body {
  background: #2a3340;
  border: 0;
  margin: 0;
  font-family: -apple-system-headline, -apple-system, "Helvetica Neue",
    "Helvetica", "Roboto", sans-serif;
}

#container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#motivational-sentence {
  font-size: 1vw;
}

@media screen and (min-width: 320px) {
  #motivational-sentence {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1000px) {
  #motivational-sentence {
    font-size: calc(20px + 1vw);
  }
}

#motivational-sentence {
  margin: 0;
  position: absolute;
  width: 80vw;
  font-weight: normal;
  text-align: center;
  text-shadow: 0 1px 0.25ex rgba(0, 0, 0, 0.3);
  color: white;
  line-height: calc(1.7em + 0.5vw);
}

#sentence-icon {
  height: 7em;
  background: url(../static/party-icon.svg) no-repeat center top;
  background-size: auto 100%;
  animation: scale-in 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

#sentence-icon.konami {
  background: url(../static/yoshiba.png) no-repeat center top;
  background-size: auto 100%;
}

#motivational-sentence h1 {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  font-size: 3em;
  line-height: 1.1em;
  -webkit-animation: fade-in 1.3s linear both;
  animation: fade-in 1.3s linear both;
}

@-webkit-keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#yoshiba {
  position: absolute;
  height: 0;
  visibility: hidden;
}

#confetti-canvas {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
}

#about {
  color: white;
}

#link-list {
  margin: 1em 0.5em;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
}

#link-list > * {
  margin: 0 0.5em;
}

.text-button {
  background: none;
  color: white;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.hidden {
  visibility: hidden;
}

html {
  visibility: visible;
  opacity: 1;
}
